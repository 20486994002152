<template>
  <div class="text-center">
    <v-dialog
      v-model="show"
      @click:outside="close"
      max-width="400"
    >
      <v-card v-if="item">
        <v-container>
              <div class="text-h5 text-center" v-if="$UserManager.getMainRole() == 'customer'">
                {{ item.supplier.business_name ?? "-" }}
              </div>
              <div class="text-h5 text-center" v-if="$UserManager.getMainRole() == 'supplier'">
                {{ item.customer.business_name ?? "-" }}
              </div>

              <v-card-text class="mt-4 text-center" v-if="$UserManager.getMainRole() == 'customer'">
                <div class="text-h4 font-weight-bold text-center">
                  {{ item.amount | currency}}
                </div>
                <div class="text-subtitle text-center">
                  Vencimiento: {{ item.expiration_date ?? "-" }}
                </div>
                <v-row class="mt-4" v-if="!purchaseExpired && !item.is_factoring">
                  <div class="text-subtitle text-center mt-4">
                    Realiza una transferencia o deposito a la cuenta del proveedor y carga el comprobante
                  </div>
                  <v-col cols="6" v-for="account in bankAccounts" :key="account.id">
                      <v-btn
                        v-if="account.bank"
                        :color="account.bank.background"
                        :class="{
                          'white--text': account.bank.background !== '#fff',
                          'black--text': account.bank.background === '#fff',
                          'd-flex': true,
                          'justify-between': true
                        }"                      
                        small
                        style="width: 100%; display: flex; justify-content: space-around;"
                        @click="handleShowBankAccountDetail(account)"
                      >
                        {{ account.bank.name }}
                        <div>
                          <v-img :src="account.bank.logo_url" class="bank-logo" />
                        </div>  
                      </v-btn>
                  </v-col>
                </v-row>
                <v-row v-else class="mt-4 text-center">
                  <v-alert
                    color="rgb(143, 57, 193)"
                    border="left"
                    style="font-size: 1em;"
                    icon="mdi-alert-circle-outline"
                    outlined
                    prominent
                  >
                    Realiza una transferencia o deposito a la cuenta de <b>Mangxo</b> y carga el comprobante
                  </v-alert>
                  <v-row>
                    <v-col cols="12">
                      <div class="text-h7 font-weight-bold">BBVA</div>
                      <div class="text-h7">
                        <div class="font-weight-bold">Cuenta</div>
                        {{mangxoAccount}}
                        <CopyBtn 
                          :content="mangxoAccount"
                        />
                      </div>
                      <div class="text-h7">
                        <div class="font-weight-bold">
                          CLABE 
                        </div>
                        {{mangxoClabe}}
                        <CopyBtn 
                        :content="mangxoClabe"
                      />
                      </div>
                    </v-col>
                  </v-row>
                </v-row>
              </v-card-text>
              <v-card-text class="mt-4 text-center" v-if="$UserManager.getMainRole() == 'supplier'">
                <div class="text-h4 font-weight-bold text-center">
                  {{ item.amount | currency}}
                </div>
                <div class="text-subtitle text-center">
                  Vencimiento: {{ item.expiration_date ?? "-" }}
                </div>
                <div class="mt-4">
                  <p class="font-weight-bold" style="color:rgb(143, 57, 193);">Método de pago</p>
                  <v-chip-group
                    v-model="paymentMethodSelected"
                    active-class="deep-purple--text"
                    show-arrows
                  >
                    <v-chip
                        v-for="pm in paymentMethods"
                        :key="pm.id"
                        :value="pm.id"
                        small
                    >
                        {{ pm.name }}
                    </v-chip>
                  </v-chip-group>
                </div>
              </v-card-text>
              <v-divider v-if="$UserManager.getMainRole() == 'customer'"></v-divider>
              <v-form>
                  <v-file-input
                    v-if="$UserManager.getMainRole() == 'customer'"
                    accept="image/*,.pdf"
                    label="Comprobante"
                    v-model="file"
                    required
                  ></v-file-input>
                  <v-file-input
                    v-if="$UserManager.getMainRole() == 'supplier'"
                    accept="image/*,.pdf"
                    label="Comprobante"
                    v-model="file"
                  ></v-file-input>
              </v-form>
              <div class="red--text">
                {{ errorMessage }}
              </div>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="success"
            @click="handleSubmit"
            :disabled="inSubmit"
          >
          {{ inSubmit ? "Cargando..."  : "Confirmar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <BankAccountDetail 
      :visible="showBankAccountDetail" 
      :account="selectedAccount"
      @close="handleShowBankAccountDetailClose"
    />
  </div>
</template>

<script>
  import PaymentService from "../services/paymentService"
  import SupplierService from "../services/supplierService"
  import BankAccountDetail from "@/components/reportPayment/BankAccountDetail"
  import CopyBtn from "@/components/CopyBtn"
  
  export default {
    props: ['visible', 'item'],

    components:{
      BankAccountDetail,
      CopyBtn
    },

    data: () => ({
        file: null,
        errorMessage: '',
        bankAccounts: [],
        selectedAccount: null,
        showBankAccountDetail: true,
        inSubmit: false,
        mangxoAccount: "0120502944",
        mangxoClabe: "012580001205029445",
        paymentMethods: [
            {
                id: 1, 
                name: "Transferencia", 
            },
            {
                id: 2, 
                name: "Efectivo", 
            },
            {
                id: 3, 
                name: "Tarjeta de crédito", 
            },
            {
                id: 4, 
                name: "Tarjeta de débito", 
            },
            {
                id: 5, 
                name: "Otro", 
            }
        ],
        paymentMethodSelected: null,
    }),

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close')
          }
        },
      },

      purchaseExpired() {
          const status = [4,5,6,10];
          return status.includes(this.item.status_id)
        }
    },

    methods: {
      async handleSubmit() {

          if (this.$UserManager.getMainRole() == 'customer' && this.file == null) {
            this.errorMessage = "Debe seleccionar un archivo"
            return
          }

          this.inSubmit = true 

          let data = {payment_id: this.item.id, file: this.file}

          if (this.$UserManager.getMainRole() == 'supplier') {
            data["payment_method"] = this.paymentMethodSelected
          }

          const response = await PaymentService.reportPayment(data)
          const status = response.status
          const result = await response.json()

          switch(status) {
            case 200:
              if (!result.success) {
                this.errorMessage = result.msg
                return
              }

              this.inSubmit = false
              this.$emit("showDialogAlert", result)
              this.$emit("refreshData")
              this.close()
            break
            case 500:
              this.errorMessage = "Ocurrio un error"
            break
            case 404:
              this.errorMessage = result.msg
            break
            case 422:
              this.errorMessage = "Debe cargar un archivo"
            break
          }

          this.inSubmit = false

      },

      async getBankAccountsBySupplier () {
        const response = await SupplierService.getBankAccountsBySupplier(this.item.supplier_id)
        const status = response.status
        const result = await response.json()

        if (status == 200) {
          this.bankAccounts = result
        }
      },

      handleShowBankAccountDetail (account) {
        this.selectedAccount = account
        this.showBankAccountDetail = true
      },

      handleShowBankAccountDetailClose () {
        this.selectedAccount = null
        this.showBankAccountDetail = false
      },

      close() {
        this.valid = false
        this.file = null
        this.errorMessage = null
        this.bankAccounts = []
        this.paymentMethodSelected = null
        this.$emit('close')
      },
    },

    filters: {
        currency(value) {
            
            var formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN'
            });
            
            return formatter.format(value);
        }
    },

    watch: {
      async show(val) {
        if (val) {
          // If the purchase has already been covered, the supplier's bank details should not be shown, only mangxo
          if (!this.purchaseExpired) {
            if (this.$UserManager.getMainRole() == "customer") {
              await this.getBankAccountsBySupplier()
            }
          }
        }
      }
    },
     
  }
</script>

<style>
  .bank-logo {
    width: 20px !important;
    margin: auto;
    /* border-radius: 1em; */
    margin-left: 1em;
  }
</style>